<template>
  <el-col
    :span="24"
    class="pz-large-margin"
  >
    <el-row :gutter="10">
      <el-col
        :span="22"
        :offset="1"
      >
        <slot name="simulador" />
      </el-col>
    </el-row>
    <slot name="buttons">
      <el-col
        :span="24"
        class="text-center"
      >
        <slot name="button_back">
          <pz-back-button
            v-if="myForm.showBack"
            :back="handleBack"
          />
        </slot>
        <slot name="button_forward">
          <pz-forward-button
            :forward="handleForward"
            :button-name="$t('forms_flujo.pz_simulators_template.btn_accept')"
          />
        </slot>
      </el-col>
    </slot>
    <el-col
      v-if="myForm.persona"
      :span="24"
      class="text-center"
    >
      <div class="pz-font-size-sm">
        <p
          v-for="(value,index) in leyenda"
          :key="index"
        >
          {{ value }}
        </p>
      </div>
    </el-col>
  </el-col>
</template>

<script>

export default {
    name: 'PzSimulatorsTemplate',
    components: {},
    inject: ['myForm'],
    provide: function () {
        return {
            myForm: this.myForm
        }
    },
    data() {
        return {
            leyenda: [ ]
        };
    },
    created() {

        if(!this.myForm.vehiculo) {
            if (this.$t('forms_flujo.pz_simulators_template.approximate_value') instanceof Object)
                this.leyenda = Object.values(this.$t('forms_flujo.pz_simulators_template.approximate_value')).map((value) => {
                    return value;
                })
            else
                this.leyenda = {1: this.$t('forms_flujo.pz_simulators_template.approximate_value')};
        }
    },
    methods: {
        handleBack() {
            return this.myForm.handleBack();
        }
        ,
        handleForward() {
            return this.myForm.handleSave();
        }
    }

}
</script>



