<script>
import baseForm from '@/commons/forms/PzBaseForm';
import {merge} from "@/commons/utils/functions";

export default {
	name: 'PzBaseFormFlujo',
	comments: [],
	mixins: [baseForm],
	provide: function () {
		return {
			myForm: this
		}
	},
	props: {
		producto: {
			type: Object,
			default: null
		},
		showBack: {
			type: Boolean,
			default: true
		},
		persona: {
			type: Boolean,
			default: true,
		},
		loan: {
			type: [String, Number],
			default: null,
		},
		routeData: {
			type: Object,
			default: () => {
				return {}
			}
		},
	},
	data() {
		return {
			defaultData: {
				ofertaView: 'default',
				withAdditionals: true,
				withCaptcha: false,
			}
		}
	},
	computed: {
		extraData() {
			return merge(this.defaultData, this.routeData);
		}
	},
	methods: {}
}
</script>
