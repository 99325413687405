import merge from 'element-ui/src/utils/merge';
import components from "@/components/Flujo/Components";

const install = function (Vue) {
    components.forEach(component => {
        Vue.component(component.name, component);
    });
};
/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

export default merge(
    {
        version: '1.0.0',
        install,
    }, components);

