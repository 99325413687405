<script>
import {required, type} from '@/commons/utils/Rules';
import {getSelds} from "@/routes/api/resources";
import moment from "moment";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import {
    getConfigSimulador,validateMontoAPrestar
} from "@/routes/api/views/flujo";
import {getFromLocal} from "@/commons/utils/storage";

export default{
    name:"PzBaseSimulador",
    mixins: [baseFormFlujo],
    data() {
        var check_prestamo = (rule, value, callback) => {
               this.$store.dispatch('loader/up', {trigger: this.$options.name})

                validateMontoAPrestar(this.producto.code_string,  this.form.monto, this.form.linea_otorgar).then((res) => {
                    if (!res.data.validate) {
                        return callback(new Error(this.$t('rules.validate_monto')));
                    } else {
                        return callback();
                    }
                }).catch(() => {
                    return callback(new Error(this.$t('rules.validate_monto')));
                }).finally(() => {
                    this.$store.dispatch('loader/down',{trigger:this.$options.name})
                });

        };
        return {
            reference: 'form',
            hoy: '',
            elOfertaView: 'solicitud',
            monto_label: '',
            fechapago_label: '',
            form: {
                monto: this.producto.min_monto,
                plazo: this.producto.min_cuota,
                cuota_sugerida: '',
                monto_oferta: '',
                trabajadores: 1,
                fecha_vencimiento: '',
                tasa: null,
                emision: '',
                cant_decimales:2,
                valor_vehiculo: '',
                valor_seguro: '',
                valor_prestamo: '',
                entrega_inicial: '',
                valor_transferencia:'',
                linea_otorgar:'',
                linea_otorgar_porc:'',
                comisiones:'',
                pago_total:'',
                intereses:'',

            },
            max_cuota:1,
            max_monto:1,
            min_cuota:1,
            min_monto:1,
            workers: [],
            mensaje: [],
            validation: {
                monto:
                    [
                        required(''),
                        type({field: '', type: 'number'}),
                    ],
                plazo:
                    [
                        required(''),
                        type({field: '', type: 'number'}),
                    ],
            },
            rules_formulario1: {
                valor_vehiculo: [required('')],
                entrega_inicial: [required('')],
                valor_seguro: [required('')],
                valor_transferencia: [required('')],
                valor_prestamo: [{validator: check_prestamo, trigger: 'blur'}],
                linea_otorgar: [{validator: check_prestamo, trigger: 'blur'}],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            pickerOptionsEmision: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        emitChange() {
            this.$nextTick(() => {
                this.$emit('simular', this.form);
            });
        },
        handleInput() {
            this.$emit('input', this.form);
        },
        async setLabels() {
            if (this.producto.needsDocuments) {
                this.monto_label = this.$t('forms_flujo.PzSimulator.how_many_discount')
                this.fechapago_label = this.$t('forms_flujo.PzSimulator.limit_date');
                this.getOfertaView('descuentos');
            } else {
                this.monto_label = this.$t('forms_flujo.PzSimulator.how_many_cash');
                this.fechapago_label = this.$t('forms_flujo.PzSimulator.first_limit_date');
                this.getOfertaView('solicitud');
            }
        },
        getOfertaView(tipe) {
            this.elOfertaView = tipe + '-' + this.extraData.ofertaView;
        },
        async stablishDate() {
            if (this.producto.needsFirstDate) {
                var date = moment().add(1, 'W').format("DD-MM-YYYY");
                this.hoy = date;
                this.form.fecha_vencimiento = date;
                this.validation.fecha_vencimiento = [required('')]
                this.form.emision = date;
                this.validation.emision = [required('')]
                this.$emit('pick', new Date(date));
            }
        },
        async stablishSelds() {
            if (this.producto.needsSelds) {
                return getSelds().then((res) => {
                    this.$store.dispatch('loader/up', {trigger: this.$options.name});
                    this.workers = res.data;
                    this.validation.trabajadores = [required('')]
                }).catch(() => {
                    this.workers = [];
                }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name});
                });
            }
        },
        async getConfSimulador() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let data_client = getFromLocal('data_client');
            var params = {
                code:this.producto.code_string,
                documento: data_client? data_client['identity']:null
            }
             await getConfigSimulador(params).then((res) => {
                var data = res.data;
                this.min_cuota = data.min_cuota;
                this.max_cuota = data.max_cuota;
                this.min_monto = data.min_monto;
                this.max_monto = data.max_monto;
                this.form.monto= data.min_monto;
                this.form.plazo =  data.min_cuota;
                 if(this.max_monto<=0 || this.max_monto< this.min_monto){
                     this.$store.dispatch('loader/down', {trigger: this.$options.name})
                     this.$router.push({name: 'Rejected'});
                 }

            }).catch((error) => {
                this.$store.dispatch('app/catchErrors', {_this: this, error: error})
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },

        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let workers = this.stablishSelds();
            let date = this.stablishDate();
            let labels = this.setLabels();
            let getConfSimulador = this.getConfSimulador();
            return Promise.all([getConfSimulador,workers, labels, date]).then(() => {
                this.emitChange();
            }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})
                }
            );
        }
    },
}
</script>


