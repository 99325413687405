<template>
  <el-col :span="24">
    <pz-title>
      <slot
        slot="title"
        name="form_title"
      />
      <slot
        slot="sub_title"
        name="form_subtitle"
      />
    </pz-title>
    <el-row class="center-block">
      <el-col
        :span="22"
        :offset="1"
      >
        <el-row :gutter="20">
          <slot name="formulario" />
        </el-row>
      </el-col>
    </el-row>
    <slot name="buttons">
      <el-col
        :span="24"
        class="text-center"
      >
        <slot name="button_back">
          <pz-back-button
            v-if="showBack"
            :back="handleBack"
          />
        </slot>
        <slot name="button_forward">
          <pz-captcha-button
            v-if="availableCaptcha"
            v-model="captchaToken"
            :forward="handleForward"
          />
          <pz-forward-button
            v-else
            :forward="handleForward"
            :button-name="nameButton"
          />
        </slot>
      </el-col>
    </slot>
    <el-col
      :span="24"
      class="text-center"
    >
      <slot name="button_footer" />
    </el-col>
  </el-col>
</template>

<script>
import PzTitle from "@/commons/titles/PzTitle";
import {isEmpty} from "@/commons/utils/functions";

export default {
	name: "PzFormFlujo",
	components: {PzTitle},
	props: {
		value: {
			type: [String, Number],
			default: '',
		},
		availableCaptcha: {
			type: Boolean,
			default: false,
		},
		showBackward: {
			type: Boolean,
			default: false,
		},
		backwards: {
			type: Function,
			default: null
		},
		forward: {
			type: Function,
			required: true
		},
        nameforward: {
            type: String,
            default: null
        }
	},
	data() {
		return {
			captchaToken: ''
		}
	},
	computed: {
		showBack() {
			return !isEmpty(this.backwards) || this.showBackward
		},
        nameButton() {
            return isEmpty(this.nameforward)?'':this.nameforward
        },
	},
	watch: {
		captchaToken(value) {
			if (this.availableCaptcha)
				this.$emit('input', value);
		},
		value(value) {
			if (value === this.captchaToken) return;
			this.captchaToken = value;
		}
		
	},
	methods: {
		handleBack() {
			if (!isEmpty(this.backwards))
				return this.backwards();
			if (this.showBackward)
				return this.$router.go(-1);
		},
		handleForward() {
			return this.forward();
		}
	}
}
</script>

<style scoped>

</style>